
import SlaReportService from "@/api/SlaReportService";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SlaReportOverview",
  data() {
    return {
      slaReports: [],
    };
  },
  created() {
    this.getSlaReports();
  },
  methods: {
    async getSlaReports(): Promise<void> {
      try {
        this.slaReports = await SlaReportService.getSlaReports();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die SLA-Reports konnten nicht erfolgreich geladen werden.",
        });
      }
    },
  },
});
