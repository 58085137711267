
import InstanceService from "@/api/InstanceService";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MigrationDetails",
  data: () => ({
    instanceId: "",
    bankName: "",
    urlOld: "",
    urlNew: "",
    tlsCrt: "",
    tlsKey: "",
    toggleMigrateDialog: false,
    deleteWebkooks: false,
    registerWebhooks: false,
    validate: false,
  }),
  created() {
    this.getInstance(this.$route.params.id);
  },
  methods: {
    async getInstance(id) {
      let instance;
      try {
        instance = await InstanceService.getInstanceById(id);
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Instanz konnte nicht erfolgreich geladen werden.",
        });
      }
      this.instanceId = id;
      this.bankName = instance.bankName;
      this.urlOld = instance.url;
    },
    async migrateDomain() {
      const validation = await this.$refs.form.validate();
      if (!validation || !this.deleteWebkooks || !this.registerWebhooks) {
        this.validate = true;
        this.toggleMigrateDialog = false;
        return false;
      }

      const migrationData = {
        domain: this.urlNew,
        crt: btoa(this.tlsCrt),
        key: btoa(this.tlsKey),
      };

      try {
        await InstanceService.migrateDomain(this.instanceId, migrationData);
        this.$q.notify({
          type: "positive",
          message: "Die Domain wurde erfolgreich migriert.",
        });
        this.$router.push({ name: "migration-overview" });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Domain konnte nicht erfolgreich migriert werden.",
        });
        this.$router.push({ name: "migration-overview" });
      }
    },
    back() {
      this.$router.push({ name: "migration-overview" });
    },
  },
});
