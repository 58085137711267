import { AxiosResponse } from "axios";
import ApiClient from "./ApiClient";

const BillingDataService = {
  async getBillingData() {
    return await ApiClient.get("/billing-data", { responseType: "blob" });
  },
  async getBillingDataById(id: string): Promise<AxiosResponse> {
    return await ApiClient.get(`/billing-data/${id}`, { responseType: "blob" });
  },
};

export default BillingDataService;
