
export default {
  data() {
    return {
      email: "",
      password: "",
      isPwd: true,
      rules: [
        (value) => !!value || "Pflichtfeld",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ungültige E-Mail-Adresse";
        },
      ],
    };
  },
  methods: {
    async auth(email, password) {
      try {
        await this.$store.dispatch("login", {
          email: email,
          password: password,
        });
        this.$router.push({ name: "instance-overview" });
        this.$q.notify({
          type: "positive",
          message: "Der Login was erfolgreich.",
        });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Der Login konnte nicht erfolgreich durchgeführt werden.",
        });
      }
      this.$router.push({ name: "instance-overview" });
    },
  },
};
