
export default {
  name: "LayoutDefault",
  created() {
    this.$store.dispatch("tryLogin");
  },
  computed: {
    showMenu() {
      return !this.$route.meta.hideMenu;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout", this.$store.getters.getToken);
      await this.$router.push({ name: "login" });
    },
  },
};
