import axios from "axios";

interface BaseConfig {
  apiUrl: string;
  baseUrl: string;
  instanceUrl: string;
}

export const getBaseConfig = async (): Promise<BaseConfig> => {
  const config = await axios.get("/config.json");
  return config.data;
};
