import { AxiosResponse } from "axios";
import ApiClient from "./ApiClient";

const SlaReportService = {
  async getSlaReports(): Promise<AxiosResponse<[]>> {
    const slaReport = await ApiClient.get("/sla-report");
    return slaReport.data.slaReport;
  },
};

export default SlaReportService;
