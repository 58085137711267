
import InvitationService from "@/api/InvitationService";

export default {
  data() {
    return {
      canVerify: false,
      invitationId: "",
      email: "",
      activationCode: "",
      password1: "",
      password2: "",
      isPwd: true,
      rules: [
        (value) => !!value || "Pflichtfeld",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ungültige E-Mail-Adresse";
        },
      ],
    };
  },
  created() {
    this.checkVerify();
  },
  methods: {
    async checkVerify() {
      this.invitationId = this.$route.params.invitationId;
      this.email = decodeURIComponent(this.$route.params.email);
      this.activationCode = this.$route.params.activationCode;

      try {
        await InvitationService.getVerifyStatus(
          this.invitationId,
          this.email,
          this.activationCode
        );
        this.canVerify = true;
      } catch {
        this.canVerify = false;
      }
    },
    async accept() {
      try {
        await InvitationService.acceptInvitation({
          invitationId: this.invitationId,
          email: this.email,
          activationCode: this.activationCode,
          password1: this.password1,
          password2: this.password2,
        });
        this.$q.notify({
          type: "positive",
          message: "Die Verifizierung war erfolgreich.",
        });
        this.$router.push({ name: "login" });
      } catch {
        this.$q.notify({
          type: "negative",
          message:
            "Die Verifizierung konnte nicht erfolgreich durchgeführt werden.",
        });
      }
    },
  },
};
