import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "wrapper-table" }
const _hoisted_2 = { class: "q-pa-md q-mb-md" }
const _hoisted_3 = { class: "row justify-center q-gutter-md items-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.slaReports.length > 0)
          ? (_openBlock(), _createElementBlock("pre", _hoisted_4, "            " + _toDisplayString(JSON.stringify(_ctx.slaReports, null, 2)) + "\n        ", 1))
          : (_openBlock(), _createElementBlock("h2", _hoisted_5, "Es sind keine SLA-Reports verfügbar"))
      ])
    ])
  ]))
}