import ApiClient from "./ApiClient";
import JwtService from "./JwtService";

const AuthService = {
  async auth(email: string, password: string) {
    const auth = await ApiClient.post("/auth", {
      email: email,
      password: password,
    });
    return auth.data;
  },
  async refresh() {
    const token = JwtService.getToken();
    if (token) {
      const refresh = await ApiClient.post("/auth/refresh");
      return refresh.data;
    }
  },
  async signOut(token) {
    await ApiClient.post("/auth/signout", token.accessToken);
  },
};

export default AuthService;
