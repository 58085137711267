import AuthService from "@/api/AuthService";
import { getBaseConfig } from "@/api/config";
import JwtService from "@/api/JwtService";
import { createStore } from "vuex";

export default createStore({
  state: {
    config: {
      apiUrl: "",
      baseUrl: "",
      instanceUrl: "",
    },
    token: JwtService.getToken(),
    isAuthenticated: !!JwtService.getToken(),
    refreshNeeded: false,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setIsAuthenticated(state, status) {
      state.isAuthenticated = status;
    },
    setRefreshNeeded(state, isNeeded) {
      state.refreshNeeded = isNeeded;
    },
  },
  actions: {
    async init() {
      const baseConfig = await getBaseConfig();
      this.state.config.apiUrl = baseConfig.apiUrl;
      this.state.config.baseUrl = baseConfig.baseUrl;
      this.state.config.instanceUrl = baseConfig.instanceUrl;
    },
    async login(context, user) {
      const response = await AuthService.auth(user.email, user.password);
      context.dispatch("setAuth", response);
    },
    setAuth(context, response) {
      JwtService.saveToken(response.accessToken);
      context.commit("setToken", response.accessToken);
      context.commit("setIsAuthenticated", !!response.accessToken);
      context.commit("setRefreshNeeded", false);
    },
    async logout(context, accessToken) {
      await AuthService.signOut(accessToken);
      context.commit("setToken", null);
      JwtService.destroyToken();
      context.commit("setIsAuthenticated", false);
    },
    tryLogin(context) {
      const token = localStorage.getItem("token");

      if (token) {
        context.commit("setToken", token);
      }
    },
    async refresh(context) {
      context.commit("setRefreshNeeded", true);
      const response = await AuthService.refresh();
      context.dispatch("setAuth", response);
    },
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  },
  modules: {},
});
