import { AxiosResponse } from "axios";
import ApiClient from "./ApiClient";

const TemplateService = {
  async createTemplate(template: unknown): Promise<AxiosResponse<[]>> {
    return await ApiClient.post("/template", template);
  },
  async deleteTemplate(id: string) {
    return await ApiClient.delete(`/template/${id}`);
  },
  async getTemplateList(): Promise<AxiosResponse<[]>> {
    const templateList = await ApiClient.get("/template");
    return templateList.data;
  },
  async getTemplateById(id: string) {
    const template = await ApiClient.get(`/template/${id}`);
    return template.data;
  },
  async getCompatibleTemplates(id: string) {
    const compatible = await ApiClient.get(`/template/compatible/${id}`);
    return compatible.data.compatibleTemplates;
  },
};

export default TemplateService;
