
import { defineComponent } from "vue";
import TemplateService from "@/api/TemplateService";

// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-yaml";
import "prismjs/themes/prism-coy.css"; // import syntax highlighting styles

import * as jsonToYaml from "json-to-pretty-yaml";
import InstanceService from "@/api/InstanceService";

import * as yaml from "js-yaml";

export default defineComponent({
  name: "InstanceImport",
  components: {
    PrismEditor,
  },
  data: () => ({
    namespace: "",
    name: "",
    bankName: "",
    rzbk: "",
    contactPerson: "",
    contactPersonEmail: "",
    templateId: "",
    version: "",
    url: "",
    serviceUrl: "",
    ingress: undefined,
    service: undefined,
    deployment: undefined,
    job: undefined,
    pvc: undefined,
    templateOptions: [],
    template: null,
    lineNumbers: true,
  }),
  created() {
    this.getTemplates();
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.yaml); //returns html
    },
    async getTemplates() {
      try {
        this.templateOptions = await TemplateService.getTemplateList();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Templates konnten nicht erfolgreich geladen werden.",
        });
      }
    },
    back() {
      this.$router.push({ name: "instance-overview" });
    },
    async importInstance() {
      let ingress;
      let service;
      let deployment;
      let job;
      let pvc;

      try {
        ingress = yaml.load(this.ingress);
        service = yaml.load(this.service);
        deployment = yaml.load(this.deployment);
        job = yaml.load(this.job);
        pvc = yaml.load(this.pvc);
      } catch (error) {
        this.$q.notify({
          type: "negative",
          message: error.message,
          actions: [
            {
              label: "OK",
              color: "white",
            },
          ],
          timeout: 0,
        });
        return false;
      }

      try {
        await InstanceService.importInstance({
          namespace: this.namespace,
          name: this.name,
          bankName: this.bankName,
          rzbk: this.rzbk,
          contactPerson: this.contactPerson,
          contactPersonEmail: this.contactPersonEmail,
          templateId: this.templateId,
          version: this.version,
          url: this.url,
          serviceUrl: this.serviceUrl,
          ingress: ingress,
          service: service,
          deployment: deployment,
          job: job,
          pvc: pvc,
        });
        this.$q.notify({
          type: "positive",
          message: "Die Instanz wurde erfolgreich erstellt.",
        });
        this.$router.push({ name: "instance-overview" });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Instanz konnte nicht erfolgreich erstellt werden.",
        });
        this.$router.push({ name: "instance-overview" });
      }
    },
    async getTemplate() {
      try {
        const template = await TemplateService.getTemplateById(this.template);
        const ingressYaml = jsonToYaml.stringify(template.ingress);
        const serviceYaml = jsonToYaml.stringify(template.service);
        const deploymentYaml = jsonToYaml.stringify(template.deployment);
        const pvcYaml = jsonToYaml.stringify(template.pvc);
        const jobYaml = jsonToYaml.stringify(template.job);

        this.version = template.version;
        this.templateId = template.templateId;
        this.ingress = ingressYaml;
        this.service = serviceYaml;
        this.deployment = deploymentYaml;
        this.job = jobYaml;
        this.pvc = pvcYaml;
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Das Template konnte nicht erfolgreich geladen werden",
        });
      }
    },
  },
});
