import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "wrapper-table" }
const _hoisted_2 = { class: "q-pa-md q-mb-md" }
const _hoisted_3 = { class: "justify-center q-gutter-md items-center" }
const _hoisted_4 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.instanceList.length < 1)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_4, " Es wird keine kontoeroeffnung-fk.de Subdomain verwendet. "))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.instanceList, (instance, index) => {
          return (_openBlock(), _createBlock(_component_q_list, {
            key: index,
            bordered: "",
            separator: ""
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_q_item, {
                clickable: "",
                onClick: ($event: any) => (_ctx.rowClick(instance.instanceId))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(instance.bankName), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_q_item_label, { caption: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(instance.url), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]), [
                [_directive_ripple]
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])
    ])
  ]))
}