import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bbc7d67"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-table" }
const _hoisted_2 = { class: "q-pa-md q-mb-md" }
const _hoisted_3 = { class: "row justify-center q-gutter-md items-center" }
const _hoisted_4 = { class: "row justify-end q-py-md full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.templateList)
          ? (_openBlock(), _createBlock(_component_q_table, {
              key: 0,
              title: "Templates",
              "row-key": "templateId",
              rows: _ctx.templateList,
              columns: _ctx.columns,
              separator: "cell",
              pagination: _ctx.pagination
            }, {
              "body-cell-templateName": _withCtx((props) => [
                _createVNode(_component_q_td, {
                  props: props,
                  onClick: ($event: any) => (_ctx.rowClick(props.row.templateId))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.name), 1)
                  ]),
                  _: 2
                }, 1032, ["props", "onClick"])
              ]),
              "body-cell-actions": _withCtx((props) => [
                _createVNode(_component_q_td, {
                  props: props,
                  class: "flex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      outline: "",
                      size: "sm",
                      color: "primary",
                      label: "Instanz erstellen",
                      onClick: ($event: any) => (_ctx.deploy(props))
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_q_btn, {
                      outline: "",
                      size: "sm",
                      color: "primary",
                      label: "Template kopieren",
                      onClick: ($event: any) => (_ctx.copyTemplate(props))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns", "pagination"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_q_btn, {
          onClick: _ctx.createTemplate,
          color: "primary",
          label: "Template erstellen"
        }, null, 8, ["onClick"])
      ])
    ])
  ]))
}