
import { defineComponent } from "vue";
import InvitationService from "@/api/InvitationService";

export default defineComponent({
  name: "Invitation",
  data: () => ({
    name: "",
    lastName: "",
    email: "",
    role: "",
    roleOptions: ["Admin", "Nutzer"],
  }),
  methods: {
    async createInvitation() {
      let role = "";
      if (this.role === "Admin") {
        role = "admin";
      }
      if (this.role === "Nutzer") {
        role = "user";
      }
      const user = {
        name: this.name,
        lastName: this.lastName,
        email: this.email,
        role: role,
      };

      try {
        await InvitationService.createInvitation(user);
        this.$q.notify({
          type: "positive",
          message: "Die Einladung wurde erfolgreich erstellt.",
        });
        this.$router.push({ name: "invitation-overview" });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Einladung konnte nicht erfolgreich erstellt werden.",
        });
        this.$router.push({ name: "invitation-overview" });
      }
    },
    back() {
      this.$router.push({ name: "invitation-overview" });
    },
  },
});
