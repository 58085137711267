import { AxiosResponse } from "axios";
import ApiClient from "./ApiClient";

const TokenStoreService = {
  async getTokenList(): Promise<AxiosResponse<[]>> {
    const tokenList = await ApiClient.get("/token");
    return tokenList.data;
  },
  async deleteToken(tokenId: string) {
    return await ApiClient.delete(`/token/${tokenId}`);
  },
};

export default TokenStoreService;
