
import InvitationService from "@/api/InvitationService";
import { defineComponent } from "vue";

export default defineComponent({
  name: "InvitationOverview",
  data() {
    return {
      pagination: {
        rowsPerPage: 25,
      },
      invitationList: [],
      columns: [
        {
          name: "invitationId",
          label: "Id",
          align: "left",
          field: "invitationId",
          sortable: true,
        },
        {
          name: "name",
          label: "Vorname",
          align: "left",
          field: "name",
          sortable: true,
        },
        {
          name: "lastName",
          label: "Nachname",
          align: "left",
          field: "lastName",
          sortable: true,
        },
        {
          name: "email",
          align: "right",
          label: "E-Mail-Adresse",
          field: "email",
        },
        {
          name: "role",
          align: "right",
          label: "Rolle",
          field: "role",
          format: (value) => (value === "admin" ? "Admin" : "Nutzer"),
        },
        {
          name: "state",
          align: "right",
          label: "Status",
          field: "state",
        },
      ],
    };
  },
  created() {
    this.getInvitationList();
  },
  methods: {
    async getInvitationList(): Promise<void> {
      try {
        this.invitationList = await InvitationService.getInvitationList();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Einladungen konnten nicht erfolgreich geladen werden.",
        });
      }
    },
    createInvitation() {
      this.$router.push({ name: "create-invitation" });
    },
    rowClick(event, row) {
      this.$router.push({
        name: "invitation-details",
        params: { invitationId: row.invitationId },
      });
    },
  },
});
