
import { defineComponent } from "vue";
import BusinessIdService from "@/api/BusinessIdService";

export default defineComponent({
  name: "BusinessId",
  data: () => ({
    businessId: "",
    instanceUrl: "",
    instanceUrlChecked: false,
  }),
  methods: {
    async getInstanceByBusinessId(businessId) {
      try {
        this.instanceUrl = await BusinessIdService.getInstanceByBusinessId(
          businessId
        );
        this.instanceUrlChecked = true;
      } catch {
        this.instanceUrl = "";
        this.instanceUrlChecked = false;
        this.$q.notify({
          type: "negative",
          message:
            "Die Instanzsuche konnte nicht erfolgreich durchgeführt werden.",
        });
      }
    },
    back() {
      this.instanceUrlChecked = false;
    },
  },
});
