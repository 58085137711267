import store from "@/store";
import axios from "axios";
import JwtService from "./JwtService";

const ApiClient = axios.create();

ApiClient.interceptors.request.use(
  async (config) => {
    if (!store.state.config.apiUrl) {
      await store.dispatch("init");
    }
    config.baseURL = store.state.config.apiUrl;
    const token = JwtService.getToken();
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default ApiClient;
