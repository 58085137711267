import { AxiosResponse } from "axios";
import ApiClient from "./ApiClient";

const InvitationService = {
  async getInvitationList(): Promise<AxiosResponse<[]>> {
    const invitationList = await ApiClient.get("/invitation");
    return invitationList.data.invitations;
  },
  async getVerifyStatus(
    invitationId: string,
    email: string,
    activationCode: string
  ) {
    const status = await ApiClient.get(
      `/invitation/verify/${invitationId}/${email}/${activationCode}`
    );
    return status.data.status;
  },
  async acceptInvitation(invitationData: unknown): Promise<AxiosResponse<[]>> {
    return await ApiClient.post("/invitation/accept", invitationData);
  },
  async getInvitationById(invitationId: string) {
    const invitation = await ApiClient.get(`/invitation/${invitationId}`);
    return invitation.data;
  },
  async createInvitation(invitationData: unknown): Promise<AxiosResponse<[]>> {
    return await ApiClient.post("/invitation", invitationData);
  },
  async updateInvitation(
    invitationData: unknown,
    invitationId: string
  ): Promise<AxiosResponse<[]>> {
    return await ApiClient.patch(`/invitation/${invitationId}`, invitationData);
  },
  async deleteInvitation(invitationId: string) {
    return await ApiClient.delete(`/invitation/${invitationId}`);
  },
};

export default InvitationService;
