import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0730c42f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-table" }
const _hoisted_2 = { class: "q-pa-md q-mb-md" }
const _hoisted_3 = { class: "row justify-center q-gutter-md items-center" }
const _hoisted_4 = {
  key: 0,
  class: "text-center"
}
const _hoisted_5 = { class: "row justify-end q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.invitationList)
          ? (_openBlock(), _createBlock(_component_q_table, {
              key: 0,
              title: "Nutzereinladungen",
              "row-key": "invitationId",
              rows: _ctx.invitationList,
              columns: _ctx.columns,
              separator: "cell",
              pagination: _ctx.pagination,
              onRowClick: _ctx.rowClick
            }, null, 8, ["rows", "columns", "pagination", "onRowClick"]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.invitationList)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_4, " Keine Nutzereinladungen vorhanden. "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_q_btn, {
        onClick: _ctx.createInvitation,
        color: "primary",
        label: "Nutzer einladen"
      }, null, 8, ["onClick"])
    ])
  ]))
}