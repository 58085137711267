import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69f4f1d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-table" }
const _hoisted_2 = { class: "q-pa-md q-mb-md" }
const _hoisted_3 = { class: "row justify-center q-gutter-md items-center" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "row q-gutter-md justify-end full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_table, {
          title: "Instanzübersicht",
          "row-key": "instanceId",
          rows: _ctx.instanceList,
          columns: _ctx.columns,
          separator: "cell",
          pagination: _ctx.pagination
        }, {
          "body-cell-bankName": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              onClick: ($event: any) => (_ctx.rowClick(props.row.instanceId))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.bankName), 1)
              ]),
              _: 2
            }, 1032, ["props", "onClick"])
          ]),
          "body-cell-url": _withCtx((props) => [
            (props.row.url)
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "desc",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createElementVNode("a", {
                        href: 'https://' + props.row.url,
                        target: "_blank"
                      }, _toDisplayString(props.row.url), 9, _hoisted_4)
                    ]),
                    _createElementVNode("p", null, [
                      _createElementVNode("a", {
                        href: 'https://' + props.row.url + '/einstiege',
                        target: "_blank"
                      }, _toDisplayString(props.row.url + "/einstiege"), 9, _hoisted_5)
                    ]),
                    _createElementVNode("p", null, [
                      _createElementVNode("a", {
                        href: 'https://' + props.row.url + '/bankzugang',
                        target: "_blank"
                      }, _toDisplayString(props.row.url + "/bankzugang"), 9, _hoisted_6)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : (_openBlock(), _createBlock(_component_q_td, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.url), 1)
                  ]),
                  _: 2
                }, 1024))
          ]),
          "body-cell-action": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  outline: "",
                  size: "sm",
                  color: "primary",
                  label: "Abrechnung herunterladen",
                  onClick: ($event: any) => (_ctx.getBillingDataById(props))
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_q_btn, {
            onClick: _ctx.importInstances,
            color: "primary",
            label: "Instanz importieren"
          }, null, 8, ["onClick"]),
          (_ctx.instanceList)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                onClick: _ctx.getAllBillingData,
                color: "primary",
                label: "Gesamtabrechnung herunterladen"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}