
import { defineComponent } from "vue";
import TemplateService from "@/api/TemplateService";
import date from 'quasar/src/utils/date.js';;

export default defineComponent({
  name: "TemplateOverview",
  data() {
    return {
      pagination: {
        rowsPerPage: 25,
        sortBy: "createdAt",
        descending: true,
      },
      templateList: [],
      columns: [
        {
          name: "templateName",
          label: "Templatename",
          align: "left",
          field: "name",
          sortable: true,
        },
        {
          name: "version",
          label: "Version",
          align: "left",
          field: "version",
          sortable: true,
        },
        {
          name: "createdAt",
          align: "left",
          label: "Erstellt am",
          field: "createdAt",
          sortable: true,
          format: (value) =>
            date.formatDate(value, "DD.MM.YYYY, HH:mm:ss") + " Uhr",
        },
        {
          name: "actions",
          align: "right",
          label: "Aktionen",
          field: "actions",
        },
      ],
    };
  },
  created() {
    this.getTemplateList();
  },
  methods: {
    async getTemplateList(): Promise<void> {
      try {
        this.templateList = await TemplateService.getTemplateList();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Templates konnten nicht erfolgreich geladen werden.",
        });
      }
    },
    deploy(props) {
      this.$router.push({
        name: "create-deployment",
        params: { id: props.row["templateId"] },
      });
    },
    copyTemplate(props) {
      this.$router.push({
        name: "copy-template",
        params: { id: props.row["templateId"] },
      });
    },
    createTemplate() {
      this.$router.push({ name: "create-template" });
    },
    rowClick(id) {
      this.$router.push({
        name: "template-details",
        params: { id: id },
      });
    },
  },
});
