import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import CreateTemplate from "@/views/template/CreateTemplate.vue";
import TemplateOverview from "@/views/template/TemplateOverview.vue";
import CreateDeployment from "@/views/deployment/CreateDeployment.vue";
import CopyTemplate from "@/views/template/CopyTemplate.vue";
import UserOverview from "@/views/user/UserOverview.vue";
import UserDetails from "@/views/user/UserDetails.vue";
import Login from "@/views/Login.vue";
import InstanceOverview from "@/views/instance/InstanceOverview.vue";
import InstanceDetails from "@/views/instance/InstanceDetails.vue";
import InvitationDetails from "@/views/invitation/InvitationDetails.vue";
import InvitationOverview from "@/views/invitation/InvitationOverview.vue";
import Invitation from "@/views/invitation/Invitation.vue";
import InvitationVerify from "@/views/invitation/InvitationVerify.vue";
import TokenStoreOverview from "@/views/token-store/TokenStoreOverview.vue";
import InstanceImport from "@/views/instance/InstanceImport.vue";
import TemplateDetails from "@/views/template/TemplateDetails.vue";
import store from "@/store/index";
import InstanceUpgrade from "@/views/instance/InstanceUpgrade.vue";
import JwtService from "@/api/JwtService";
import MigrationOverview from "@/views/domain-migration/MigrationOverview.vue";
import MigrationDetails from "@/views/domain-migration/MigrationDetails.vue";
import BusinessId from "@/views/BusinessId.vue";
import SlaReportOverview from "@/views/sla-report/SlaReportOverview.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      hideMenu: true,
      noAuth: true,
    },
  },
  {
    path: "/",
    redirect: "/instance",
  },
  {
    path: "/deployment/create/:id",
    name: "create-deployment",
    component: CreateDeployment,
  },
  {
    path: "/template",
    name: "template-overview",
    component: TemplateOverview,
  },
  {
    path: "/template/create",
    name: "create-template",
    component: CreateTemplate,
  },
  {
    path: "/template/:id",
    name: "template-details",
    component: TemplateDetails,
  },
  {
    path: "/template/copy/:id",
    name: "copy-template",
    component: CopyTemplate,
  },
  {
    path: "/user",
    name: "user-overview",
    component: UserOverview,
  },
  {
    path: "/invitation/create",
    name: "create-invitation",
    component: Invitation,
  },
  {
    path: "/invitation",
    name: "invitation-overview",
    component: InvitationOverview,
  },
  {
    path: "/invitation/:invitationId",
    name: "invitation-details",
    component: InvitationDetails,
  },
  {
    path: "/verify/:invitationId/:email/:activationCode",
    name: "verify",
    component: InvitationVerify,
    meta: {
      hideMenu: true,
      noAuth: true,
    },
  },
  {
    path: "/user/:id",
    name: "user-details",
    component: UserDetails,
  },
  {
    path: "/instance",
    name: "instance-overview",
    component: InstanceOverview,
  },
  {
    path: "/instance/:id",
    name: "instance-details",
    component: InstanceDetails,
  },
  {
    path: "/instance/import",
    name: "instance-import",
    component: InstanceImport,
  },
  {
    path: "/instance/:id/upgrade",
    name: "instance-upgrade",
    component: InstanceUpgrade,
  },
  {
    path: "/token-store",
    name: "token-store-overview",
    component: TokenStoreOverview,
  },
  {
    path: "/domain-migration",
    name: "migration-overview",
    component: MigrationOverview,
  },
  {
    path: "/domain-migration/:id",
    name: "migration-details",
    component: MigrationDetails,
  },
  {
    path: "/business-id",
    name: "business-id",
    component: BusinessId,
  },
  {
    path: "/sla-reports",
    name: "sla-report-overview",
    component: SlaReportOverview,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(function (to, _, next) {
  if (!to.meta.noAuth && !store.getters.isAuthenticated) {
    next("/login");
  }
  if (to.meta.noAuth) {
    JwtService.destroyToken();
    next();
  } else {
    next();
  }
});

export default router;
