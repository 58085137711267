
import BillingDataService from "@/api/BillingDataService";
import InstanceService from "@/api/InstanceService";
import { defineComponent } from "vue";
import date from 'quasar/src/utils/date.js';;

export default defineComponent({
  name: "TemplateOverview",
  data() {
    return {
      pagination: {
        rowsPerPage: 25,
      },
      instanceList: [],
      columns: [
        {
          name: "bankName",
          label: "Bankname",
          align: "left",
          field: "bankName",
          sortable: true,
          classes: "bankname-table",
        },
        {
          name: "rzbk",
          label: "RZBK",
          align: "left",
          field: "rzbk",
          sortable: true,
        },
        {
          name: "url",
          label: "URL",
          align: "left",
          field: "url",
          sortable: true,
        },
        {
          name: "version",
          align: "left",
          label: "App Version",
          field: "version",
        },
        {
          name: "action",
          align: "center",
          label: "Aktion",
          field: "action",
        },
      ],
    };
  },
  created() {
    this.getInstanceList();
  },
  methods: {
    async getInstanceList(): Promise<void> {
      try {
        this.instanceList = await InstanceService.getInstanceList();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Instanzen konnten nicht erfolgreich geladen werden.",
        });
      }
    },
    async getAllBillingData() {
      try {
        const response = await BillingDataService.getBillingData();
        this.download(response, "abrechnungsdaten_bgo");
        this.$q.notify({
          type: "positive",
          message: "Die Abrechnungsdaten wurde erfolgreich erstellt.",
        });
      } catch {
        this.$q.notify({
          type: "negative",
          message:
            "Die Abrechnungsdaten konnten nicht erfolgreich erstellt werden.",
        });
      }
    },
    async getBillingDataById(props) {
      try {
        const response = await BillingDataService.getBillingDataById(
          props.row.instanceId
        );
        this.download(response, "abrechnungsdaten_" + props.row.rzbk);
        this.$q.notify({
          type: "positive",
          message: "Die Abrechnungsdaten wurde erfolgreich erstellt.",
        });
      } catch {
        this.$q.notify({
          type: "negative",
          message:
            "Die Abrechnungsdaten konnten nicht erfolgreich erstellt werden.",
        });
      }
    },
    download(response, label) {
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    rowClick(id) {
      this.$router.push({
        name: "instance-details",
        params: { id: id },
      });
    },
    importInstances() {
      this.$router.push({
        name: "instance-import",
      });
    },
  },
});
