
import { defineComponent } from "vue";
import InstanceService from "@/api/InstanceService";

export default defineComponent({
  name: "CreateDeployment",
  data: () => ({
    name: "",
    bankName: "",
    rzbk: "",
    url: "",
    tlsCrt: "",
    tlsKey: "",
    tlsExpiresAt: "",
    contactPerson: "",
    contactPersonEmail: "",
    toggleDialog: false,
    myLocale: {
      days: [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag",
      ],
      daysShort: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
      months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      monthsShort: [
        "Jan.",
        "Feb.",
        "März",
        "Apr.",
        "Mai",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sept.",
        "Okt.",
        "Nov.",
        "Dez.",
      ],
      firstDayOfWeek: 0,
    },
  }),
  methods: {
    async createInstance() {
      const validation = await this.$refs.form.validate();
      if (!validation) {
        this.toggleDialog = false;
        return false;
      }
      const httpsUrl = "https://" + this.url + "/";
      this.toggleDialog = false;
      this.$q.loading.show({
        message: "Instanz wird erstellt",
      });
      try {
        await InstanceService.createInstance({
          templateId: this.$route.params.id,
          namespace: "bgo",
          customerDetails: {
            rzbk: this.rzbk,
            bankName: this.bankName,
            contactPerson: this.contactPerson,
            contactPersonEmail: this.contactPersonEmail,
          },
          inputs: {
            name: this.name,
            url: this.url,
            urls: [this.url],
            customerClientUrl: httpsUrl,
            backendUrl: httpsUrl,
            webhookBackendUrl: httpsUrl,
            tlsCrt: btoa(this.tlsCrt),
            tlsKey: btoa(this.tlsKey),
            tlsExpiresAt: this.tlsExpiresAt,
          },
        });
        this.$q.notify({
          type: "positive",
          message: "Die Instanz wurde erfolgreich erstellt.",
        });
        this.$router.push({ name: "instance-overview" });
      } catch (error) {
        this.$q.loading.hide();
        this.$q.notify({
          type: "negative",
          message: error.message,
        });
      }
      this.$q.loading.hide();
    },
    back() {
      this.$router.push({ name: "template-overview" });
    },
  },
});
