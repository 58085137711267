
import InvitationService from "@/api/InvitationService";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CreateUser",
  data: () => ({
    isEditing: false,
    invitationId: "",
    name: "",
    lastName: "",
    email: "",
    role: "",
    invitationLink: "",
    roleOptions: ["Admin", "Nutzer"],
    toggleDialog: false,
  }),
  created() {
    this.getInvitation(this.$route.params.invitationId);
  },
  methods: {
    async getInvitation(id) {
      let invitation;
      try {
        invitation = await InvitationService.getInvitationById(id);
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Einladung konnte nicht erfolgreich geladen werden.",
        });
      }
      this.invitationId = id;
      this.name = invitation.name;
      this.lastName = invitation.lastName;
      this.email = invitation.email;
      this.role = invitation.role;

      this.invitationLink =
        this.$store.state.config.baseUrl +
        "/verify/" +
        id +
        "/" +
        encodeURIComponent(this.email) +
        "/" +
        invitation.activationCode;

      if (this.role === "admin") {
        this.role = "Admin";
      }
      if (this.role === "user") {
        this.role = "Nutzer";
      }
    },
    async updateInvitation() {
      let role = "";
      if (this.role === "Admin") {
        role = "admin";
      }
      if (this.role === "Nutzer") {
        role = "user";
      }
      const user = {
        name: this.name,
        lastName: this.lastName,
        role: role,
      };

      try {
        await InvitationService.updateInvitation(user, this.invitationId);
        this.isEditing = false;
        this.$q.notify({
          type: "positive",
          message: "Die Einladung wurde erfolgreich editiert.",
        });
        this.$router.push({ name: "invitation-overview" });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Einladung konnte nicht erfolgreich editiert werden.",
        });
        this.$router.push({ name: "invitation-overview" });
      }
    },
    async deleteInvitation() {
      try {
        this.$q.notify({
          type: "positive",
          message: "Die Einladung wurde erfolgreich gelöscht.",
        });
        this.toggleDialog = false;
        await InvitationService.deleteInvitation(this.invitationId);
        this.$router.push({ name: "invitation-overview" });
      } catch {
        this.toggleDialog = false;
        this.$q.notify({
          type: "negative",
          message: "Die Einladung konnte nicht erfolgreich gelöscht werden.",
        });
        this.$router.push({ name: "invitation-overview" });
      }
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    back() {
      this.$router.push({ name: "invitation-overview" });
    },
    cancel() {
      this.getInvitation(this.invitationId);
      this.toggleEdit();
    },
  },
});
