
import InstanceService from "@/api/InstanceService";
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  name: "InstanceDetails",
  data: () => ({
    instanceId: "",
    bankName: "",
    rzbk: "",
    url: "",
    urlEntrances: "",
    urlBankEntrance: "",
    version: "",
    contactPerson: "",
    contactPersonEmail: "",
    createdAt: "",
    toggleDeleteDialog: false,
  }),
  created() {
    this.getInstance(this.$route.params.id);
  },
  methods: {
    async getInstance(id) {
      let instance;
      try {
        instance = await InstanceService.getInstanceById(id);
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Instanz konnte nicht erfolgreich geladen werden.",
        });
      }
      this.instanceId = id;
      this.bankName = instance.bankName;
      this.rzbk = instance.rzbk;
      this.url = instance.url;
      this.urlEntrances = instance.url + "/einstiege";
      this.urlBankEntrance = instance.url + "/bankzugang";
      this.version = instance.version;
      this.contactPerson = instance.contactPerson;
      this.contactPersonEmail = instance.contactPersonEmail;
      this.createdAt =
        moment(String(instance.createdAt)).format("DD.MM.YYYY, hh:mm") + " Uhr";
    },
    async updateInstance() {
      const instance = {
        bankName: this.bankName,
        rzbk: this.rzbk,
        contactPerson: this.contactPerson,
        contactPersonEmail: this.contactPersonEmail,
      };

      try {
        await InstanceService.updateInstance(instance, this.instanceId);
        this.$q.notify({
          type: "positive",
          message: "Die Instanz wurde erfolgreich editiert.",
        });
        this.$router.push({ name: "instance-overview" });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Instanz konnte nicht erfolgreich editiert werden.",
        });
        this.$router.push({ name: "instance-overview" });
      }
    },
    back() {
      this.$router.push({ name: "instance-overview" });
    },
    upgradeInstance() {
      this.$router.push({
        name: "instance-upgrade",
      });
    },
    async deleteInstance() {
      try {
        this.toggleDeleteDialog = false;
        await InstanceService.deleteInstance(this.instanceId);
        this.$q.notify({
          type: "positive",
          message: "Die Instanz wurde erfolgreich gelöscht.",
        });
        this.$router.push({ name: "instance-overview" });
      } catch {
        this.toggleDeleteDialog = false;
        this.$q.notify({
          type: "negative",
          message: "Die Instanz konnte nicht erfolgreich gelöscht werden.",
        });
        this.$router.push({ name: "instance-overview" });
      }
    },
  },
});
