import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_route_tab = _resolveComponent("q-route-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hHh lpR fFf" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        ($options.showMenu)
          ? (_openBlock(), _createBlock(_component_q_toolbar, {
              key: 0,
              class: "bg-primary text-white shadow-2 q-pa-sm q-mb-xl"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  label: "Ausbringungsassistent"
                }),
                _createVNode(_component_q_space),
                _createVNode(_component_q_tabs, { shrink: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_route_tab, {
                      to: { name: 'template-overview' },
                      exact: "",
                      replace: "",
                      label: "Templates"
                    }),
                    _createVNode(_component_q_route_tab, {
                      to: { name: 'instance-overview' },
                      exact: "",
                      replace: "",
                      label: "Instanzen"
                    }),
                    _createVNode(_component_q_route_tab, {
                      to: { name: 'user-overview' },
                      exact: "",
                      replace: "",
                      label: "Nutzer"
                    }),
                    _createVNode(_component_q_route_tab, {
                      to: { name: 'invitation-overview' },
                      exact: "",
                      replace: "",
                      label: "Einladungen"
                    }),
                    _createVNode(_component_q_route_tab, {
                      to: { name: 'token-store-overview' },
                      exact: "",
                      replace: "",
                      label: "Sitzungen"
                    }),
                    _createVNode(_component_q_route_tab, {
                      to: { name: 'migration-overview' },
                      exact: "",
                      replace: "",
                      label: "Domain-Migration"
                    }),
                    _createVNode(_component_q_route_tab, {
                      to: { name: 'business-id' },
                      exact: "",
                      replace: "",
                      label: "Instanzsuche"
                    }),
                    _createVNode(_component_q_route_tab, {
                      to: { name: 'sla-report-overview' },
                      exact: "",
                      replace: "",
                      label: "SLA-Reports"
                    }),
                    ($options.isLoggedIn)
                      ? (_openBlock(), _createBlock(_component_q_route_tab, {
                          key: 0,
                          to: { name: 'login' },
                          onClick: $options.logout,
                          exact: "",
                          replace: "",
                          label: "Logout"
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}