import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import "./styles/standards.scss";
import ApiClient from "./api/ApiClient";
import JwtService from "./api/JwtService";

ApiClient.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      const token = JwtService.getToken();
      if (token) {
        const parts = token.split(".");
        const payload = JSON.parse(atob(parts[1]));
        if (
          !store.state.refreshNeeded &&
          payload.exp <= Date.now() / 1000 + 1800
        ) {
          store.dispatch("refresh");
        }
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status && error.response.status === 401) {
      JwtService.destroyToken();
      store.commit("setIsAuthenticated", false);
      store.commit("setToken", null);
      if (!store.getters.isAuthenticated) {
        router.replace({
          path: "/login",
        });
      }
    }
    return Promise.reject(error.response);
  }
);

createApp(App)
  .use(Quasar, quasarUserOptions, {
    plugins: {
      Notify,
      Loading,
    },
  })
  .use(store)
  .use(router)
  .mount("#app");
