
import { defineComponent } from "vue";
import UserService from "@/api/UserService";

export default defineComponent({
  name: "UserOverview",
  data() {
    return {
      pagination: {
        rowsPerPage: 25,
      },
      userList: [],
      columns: [
        {
          name: "userId",
          label: "Id",
          align: "left",
          field: "userId",
          sortable: true,
        },
        {
          name: "name",
          label: "Vorname",
          align: "left",
          field: "name",
          sortable: true,
        },
        {
          name: "lastName",
          label: "Nachname",
          align: "left",
          field: "lastName",
          sortable: true,
        },
        {
          name: "email",
          align: "right",
          label: "E-Mail-Adresse",
          field: "email",
        },
        {
          name: "role",
          align: "right",
          label: "Rolle",
          field: "role",
          format: (value) => (value === "admin" ? "Admin" : "Nutzer"),
        },
      ],
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    async getUserList(): Promise<void> {
      this.userList = await UserService.getUserList();
    },
    createInvitation() {
      this.$router.push({ name: "create-invitation" });
    },
    rowClick(event, row) {
      this.$router.push({ name: "user-details", params: { id: row.userId } });
    },
  },
});
