
import TokenStoreService from "@/api/TokenStoreService";
import { defineComponent } from "vue";
import * as moment from "moment";

export default defineComponent({
  name: "TokenStoreOverview",
  data() {
    return {
      pagination: {
        rowsPerPage: 25,
      },
      tokenList: [],
      columns: [
        {
          name: "name",
          label: "Vorname",
          align: "left",
          field: "name",
          sortable: true,
        },
        {
          name: "lastName",
          label: "Nachname",
          align: "left",
          field: "lastName",
          sortable: true,
        },
        {
          name: "issuedAt",
          label: "Austellungszeitpunkt",
          align: "left",
          field: "issuedAt",
          sortable: true,
          format: (value) =>
            moment.unix(value).format("DD.MM.YYYY, HH:mm:ss") + " Uhr",
        },
        {
          name: "expiresAt",
          align: "left",
          label: "Ablaufzeitpunkt",
          field: "expiresAt",
          format: (value) =>
            moment.unix(value).format("DD.MM.YYYY, HH:mm:ss") + " Uhr",
        },
        {
          name: "actions",
          align: "center",
          label: "Aktionen",
          field: "actions",
        },
      ],
    };
  },
  created() {
    this.getTokenList();
  },
  methods: {
    async getTokenList(): Promise<void> {
      try {
        this.tokenList = await TokenStoreService.getTokenList();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Templates konnten nicht erfolgreich geladen werden.",
        });
      }
    },
    async deleteToken(prop) {
      try {
        await TokenStoreService.deleteToken(prop.row.jwtId);
        this.getTokenList();
        this.$q.notify({
          type: "positive",
          message: "Die Sitzung wurde erfolgreich beendet.",
        });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Sitzung konnte nicht erfolgreich beendet werden.",
        });
      }
    },
  },
});
