import { AxiosResponse } from "axios";
import ApiClient from "./ApiClient";
import ApiClientDeployment from "./ApiClientDeployment";

const InstanceService = {
  async getInstanceList(): Promise<AxiosResponse<[]>> {
    const instanceList = await ApiClient.get("/instance");
    return instanceList.data.instances;
  },
  async getInstanceById(id: string) {
    const instance = await ApiClient.get(`/instance/${id}`);
    return instance.data;
  },
  async updateInstance(
    InstanceData: unknown,
    id: string
  ): Promise<AxiosResponse<[]>> {
    return await ApiClient.patch(`/instance/${id}`, InstanceData);
  },

  async upgradeInstance(id: string, templateId) {
    return await ApiClient.patch(`/instance/${id}/upgrade`, templateId);
  },

  async getDeploymentAggregates(): Promise<AxiosResponse<[]>> {
    const deploymentAggregates = await ApiClientDeployment.get(
      "/instance/aggregates"
    );
    return deploymentAggregates.data;
  },

  async createInstance(instance: unknown): Promise<AxiosResponse<[]>> {
    return await ApiClient.post("/instance", instance);
  },

  async importInstance(instance: unknown) {
    return await ApiClient.post("/instance/import", instance);
  },

  async deleteInstance(id: string): Promise<AxiosResponse<string>> {
    return await ApiClient.delete(`/instance/${id}`);
  },

  async migrateDomain(id: string, migrationData: unknown) {
    return await ApiClient.patch(
      `/instance/${id}/migrate-domain`,
      migrationData
    );
  },
};

export default InstanceService;
