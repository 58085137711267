import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c71ac23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-table" }
const _hoisted_2 = { class: "q-pa-md q-mb-md" }
const _hoisted_3 = { class: "row justify-center q-gutter-md items-center" }
const _hoisted_4 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.tokenList)
          ? (_openBlock(), _createBlock(_component_q_table, {
              key: 0,
              title: "Aktive Sitzungen",
              "row-key": "jwtId",
              rows: _ctx.tokenList,
              columns: _ctx.columns,
              separator: "cell",
              pagination: _ctx.pagination
            }, {
              "body-cell-actions": _withCtx((props) => [
                _createVNode(_component_q_td, {
                  props: props,
                  class: "flex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      size: "sm",
                      color: "red",
                      icon: "far fa-trash-alt",
                      onClick: ($event: any) => (_ctx.deleteToken(props))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns", "pagination"]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.tokenList)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_4, " Keine aktiven Sitzungen vorhanden. "))
        : _createCommentVNode("", true)
    ])
  ]))
}