
import { defineComponent } from "vue";
import UserService from "@/api/UserService";

export default defineComponent({
  name: "CreateUser",
  data: () => ({
    isEditing: false,
    userId: "",
    name: "",
    lastName: "",
    email: "",
    role: "",
    roleOptions: ["Admin", "Nutzer"],
    toggleDeleteDialog: false,
    toggleDeaktivateDialog: false,
    isActive: false,
  }),
  created() {
    this.getUser(this.$route.params.id);
  },
  methods: {
    async getUser(id) {
      let user;
      try {
        user = await UserService.getUserById(id);
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Der Nutzer konnte nicht erfolgreich geladen werden.",
        });
        this.$router.push({ name: "user-overview" });
      }
      this.userId = id;
      this.name = user.name;
      this.lastName = user.lastName;
      this.email = user.email;
      this.role = user.role;
      this.isActive = user.active;

      if (this.role === "admin") {
        this.role = "Admin";
      }
      if (this.role === "user") {
        this.role = "Nutzer";
      }
    },
    async updateUser() {
      let role = "";
      if (this.role === "Admin") {
        role = "admin";
      }
      if (this.role === "Nutzer") {
        role = "user";
      }
      const user = {
        name: this.name,
        lastName: this.lastName,
        email: this.email,
        role: role,
      };

      try {
        await UserService.updateUser(user, this.userId);
        this.isEditing = false;
        this.$q.notify({
          type: "positive",
          message: "Der Nutzer wurde erfolgreich editiert.",
        });
        this.$router.push({ name: "user-overview" });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Der Nutzer konnte nicht erfolgreich editiert werden.",
        });
        this.$router.push({ name: "user-overview" });
      }
    },
    async deleteUser() {
      try {
        this.toggleDeleteDialog = false;
        await UserService.deleteUser(this.userId);
        this.$q.notify({
          type: "positive",
          message: "Der Nutzer wurde erfolgreich gelöscht.",
        });
        this.$router.push({ name: "user-overview" });
      } catch {
        this.toggleDeleteDialog = false;
        this.$q.notify({
          type: "negative",
          message: "Der Nutzer konnte nicht erfolgreich gelöscht werden.",
        });
        this.$router.push({ name: "user-overview" });
      }
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    back() {
      this.$router.push({ name: "user-overview" });
    },
    cancel() {
      this.getUser(this.userId);
      this.toggleEdit();
    },
    async toggleActivate() {
      const active = {
        active: !this.isActive,
      };
      try {
        await UserService.activateUser(this.userId, active);
        this.getUser(this.userId);
        this.toggleDeaktivateDialog = false;
        let message = "";
        if (!this.isActive) {
          message = "Der Nutzer konnte erfolgreich aktiviert werden.";
        } else {
          message = "Der Nutzer konnte erfolgreich deaktiviert werden.";
        }
        this.$q.notify({
          type: "positive",
          message: message,
        });
      } catch {
        this.$q.notify({
          type: "negative",
          message:
            "Der Nutzer konnte nicht erfolgreich aktiviert/deaktiviert werden.",
        });
      }
    },
  },
});
