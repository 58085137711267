
import InstanceService from "@/api/InstanceService";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MigrationOverview",
  data() {
    return {
      instanceList: [],
    };
  },
  created() {
    this.getInstanceList();
  },
  methods: {
    async getInstanceList(): Promise<void> {
      try {
        this.instanceList = await InstanceService.getInstanceList();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Instanzen konnten nicht erfolgreich geladen werden.",
        });
      }
      var migrationList = [];
      this.instanceList.forEach((instance) => {
        if (instance.url.includes("kontoeroeffnung-fk")) {
          migrationList.push(instance);
        }
      });
      this.instanceList = migrationList;
    },
    rowClick(id) {
      this.$router.push({
        name: "migration-details",
        params: { id: id },
      });
    },
  },
});
