import ApiClient from "./ApiClient";

const BusinessIdService = {
  async getInstanceByBusinessId(businessId) {
    const instance = await ApiClient.get(`/businessid/${businessId}`);
    return instance.data.bankName;
  },
};

export default BusinessIdService;
