import { AxiosResponse } from "axios";
import ApiClient from "./ApiClient";

const UserService = {
  async updateUser(userData: unknown, id: string): Promise<AxiosResponse<[]>> {
    return await ApiClient.patch(`/user/${id}`, userData);
  },
  async getUserList(): Promise<AxiosResponse<[]>> {
    const userList = await ApiClient.get("/user");
    return userList.data.users;
  },
  async getUserById(id: string) {
    const user = await ApiClient.get(`/user/${id}`);
    return user.data;
  },
  async activateUser(id: string, active: unknown) {
    return await ApiClient.patch(`/user/activate/${id}`, active);
  },
  async deleteUser(id: string) {
    return await ApiClient.delete(`/user/${id}`);
  },
};

export default UserService;
