
import { defineComponent } from "vue";
import TemplateService from "@/api/TemplateService";

// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-yaml";
import "prismjs/themes/prism-coy.css"; // import syntax highlighting styles

import * as yaml from "js-yaml";

export default defineComponent({
  name: "CreateTemplate",
  components: {
    PrismEditor,
  },
  data: () => ({
    templateName: "",
    version: "",
    app: "bgo",
    requiredFields: undefined,
    ingress: undefined,
    service: undefined,
    deployment: undefined,
    job: undefined,
    pvc: undefined,
    mapping: undefined,
    autoGenSecrets: undefined,
    secret: undefined,
    tlsSecret: undefined,
    compatibleWithOptions: [],
    compatibleWith: null,
    lineNumbers: true,
    validate: false,
  }),
  created() {
    this.getTemplates();
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.yaml); //returns html
    },
    async getTemplates() {
      try {
        this.compatibleWithOptions = await TemplateService.getTemplateList();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Templates konnten nicht erfolgreich geladen werden.",
        });
      }
    },
    async createTemplate() {
      this.validate = true;
      let autoGenSecretsArray = undefined;

      if (this.autoGenSecrets) {
        autoGenSecretsArray = this.autoGenSecrets
          .split(",")
          .map((s) => s.trim());
      }

      let ingress;
      let service;
      let deployment;
      let job;
      let pvc;
      let mapping;
      let secret;
      let tlsSecret;

      try {
        ingress = yaml.load(this.ingress);
        service = yaml.load(this.service);
        deployment = yaml.load(this.deployment);
        job = yaml.load(this.job);
        pvc = yaml.load(this.pvc);
        mapping = yaml.load(this.mapping);
        secret = yaml.load(this.secret);
        if (tlsSecret) {
          tlsSecret = yaml.load(this.tlsSecret);
        }
      } catch (error) {
        this.$q.notify({
          type: "negative",
          message: error.message,
          actions: [
            {
              label: "OK",
              color: "white",
            },
          ],
          timeout: 0,
        });
        return false;
      }

      const template = {
        name: this.templateName,
        version: this.version,
        app: this.app,
        autoGenSecrets: autoGenSecretsArray,
        ingress: ingress,
        service: service,
        deployment: deployment,
        job: job,
        pvc: pvc,
        mapping: mapping,
        secret: secret,
        tlsSecret: tlsSecret,
        compatibleWith: this.compatibleWith,
      };

      if (
        this.ingress &&
        this.job &&
        this.mapping &&
        this.pvc &&
        this.secret &&
        this.deployment &&
        this.service
        //  && this.tlsSecret
      ) {
        try {
          await TemplateService.createTemplate(template);
          this.$q.notify({
            type: "positive",
            message: "Das Template wurde erfolgreich erstellt.",
          });
          this.$router.push({ name: "template-overview" });
        } catch (error) {
          this.$q.notify({
            type: "negative",
            message: error.data.message,
            actions: [
              {
                label: "OK",
                color: "white",
              },
            ],
            timeout: 0,
          });
        }
      } else {
        this.$q.notify({
          type: "negative",
          message: "Es müssen alle Kubernetes-Objekte ausgefüllt sein.",
        });
      }
    },
    back() {
      this.$router.push({ name: "template-overview" });
    },
  },
});
