
import InstanceService from "@/api/InstanceService";
import TemplateService from "@/api/TemplateService";
import { defineComponent } from "vue";

export default defineComponent({
  name: "InstanceDetails",
  data: () => ({
    instanceId: "",
    bankName: "",
    rzbk: "",
    version: "",
    templateId: "",
    compatibleWithOptions: [],
    targetTemplate: "",
    toggleDialog: false,
  }),
  async created() {
    await this.getInstance(this.$route.params.id);
    await this.getCompatibleTemplates();
  },
  methods: {
    async getInstance(id) {
      let instance;
      try {
        instance = await InstanceService.getInstanceById(id);
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Instanz konnte nicht erfolgreich geladen werden.",
        });
      }
      this.instanceId = id;
      this.bankName = instance.bankName;
      this.rzbk = instance.rzbk;
      this.version = instance.version;
      this.templateId = instance.templateId;
    },
    async getCompatibleTemplates() {
      if (this.templateId) {
        try {
          this.compatibleWithOptions =
            await TemplateService.getCompatibleTemplates(this.templateId);
        } catch {
          this.$q.notify({
            type: "negative",
            message:
              "Die kompatiblen Templates konnten nicht erfolgreich geladen werden.",
          });
        }
      }
    },
    async upgradeInstance() {
      try {
        await InstanceService.upgradeInstance(this.instanceId, {
          targetTemplateId: this.targetTemplate,
        });
        this.$q.notify({
          type: "positive",
          message: "Die Instanz wurde erfolgreich aktualisiert.",
        });
        this.$router.push({ name: "instance-details" });
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Instanz konnte nicht erfolgreich aktualisiert werden.",
        });
        this.$router.push({ name: "instance-details" });
      }
    },
    setDialog() {
      this.toggleDialog = true;
    },
    back() {
      this.$router.push({ name: "instance-details" });
    },
  },
});
